import { render, staticRenderFns } from "./Tag.vue?vue&type=template&id=ab624324&scoped=true&"
import script from "./Tag.vue?vue&type=script&lang=js&"
export * from "./Tag.vue?vue&type=script&lang=js&"
import style0 from "./Tag.vue?vue&type=style&index=0&id=ab624324&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab624324",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\xian\\work\\datacockpit\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ab624324')) {
      api.createRecord('ab624324', component.options)
    } else {
      api.reload('ab624324', component.options)
    }
    module.hot.accept("./Tag.vue?vue&type=template&id=ab624324&scoped=true&", function () {
      api.rerender('ab624324', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/Mall/component/Tag.vue"
export default component.exports