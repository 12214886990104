<template>
    <div class="box">
        <div class="box-title">{{data.name}}</div>
        <div class="box-buttons">
            <div class="botton" v-for="item in data.valueList" :key="item.id" @click="setData(item)" :class="[item.id == index ? 'Selected' : '']">
                {{item.value}}</div>
        </div>
    </div>
</template>

<script>
    export default {
        props:[
            'data',
            'ID'
        ],
        data() {
            return {
                index: ''
            }
        },
        methods:{
            setData(e){
                // debugger
                if(this.index === e.id){
                    this.index = -1
                    this.$emit('getData',null,this.ID)
                }else{
                    this.index = e.id
                    this.$emit('getData',e,this.ID)
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .box {
        display: flex;
        margin-top: 18px;

        .box-title {
            min-width: 100px;
            text-align: justify;
            text-align-last: justify;
            color: #A9A9A9;
            padding: 0 24px;
            box-sizing: border-box;
        }

        .box-buttons {
            display: flex;
            max-width: 380px;
            flex-wrap: wrap;

            .botton {
                margin: 0 10px 10px 0;
                border: 1px solid #DCDFE6;
                padding: 12px 20px;
                box-sizing: border-box;
                cursor: pointer;
                border-radius: 4px;

                &.Selected {
                    border-color: #e3393c;
                }
            }
        }
    }
</style>
