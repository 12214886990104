var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrap" }, [
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        { staticClass: "breadcrumb" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { "separator-class": "el-icon-arrow-right" } },
            [
              _c(
                "el-breadcrumb-item",
                { attrs: { to: { path: "/home/HomePage" } } },
                [_vm._v("首页")]
              ),
              _c("el-breadcrumb-item", [_vm._v("产品详情")]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "main" }, [
          _c("div", { staticClass: "info" }, [
            _c("div", { staticClass: "info-left" }, [
              _c(
                "div",
                { staticClass: "image-box" },
                [
                  _c(
                    "el-image",
                    { attrs: { src: _vm.goods.pic_url, fit: "fill" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "image-slot",
                          attrs: { slot: "placeholder" },
                          slot: "placeholder",
                        },
                        [
                          _vm._v(" 加载中"),
                          _c("span", { staticClass: "dot" }, [_vm._v("...")]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "image-list" },
                _vm._l(_vm.goods.gallery, function (item) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass: "image-item",
                      on: {
                        click: function ($event) {
                          return _vm.selectedPreview(item)
                        },
                      },
                    },
                    [
                      _c("el-image", { attrs: { src: item, fit: "cover" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "image-slot",
                            attrs: { slot: "placeholder" },
                            slot: "placeholder",
                          },
                          [
                            _vm._v(" 加载中"),
                            _c("span", { staticClass: "dot" }, [_vm._v("...")]),
                          ]
                        ),
                      ]),
                    ],
                    1
                  )
                }),
                0
              ),
            ]),
            _c(
              "div",
              { staticClass: "info-right" },
              [
                _c("div", { staticClass: "name" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.goods.existing ? "期货" : "现货")),
                  ]),
                  _c("p", [_vm._v(_vm._s(_vm.goods.name))]),
                ]),
                _c("div", { staticClass: "box1 price" }, [
                  _c("span", [_vm._v("商品价格")]),
                  _vm.isExtis
                    ? _c("p", [_vm._v("¥" + _vm._s(_vm.goods.retail_price))])
                    : _vm.goods.is_lvding_price
                    ? _c("p", [
                        _vm._v(
                          "¥" +
                            _vm._s(
                              isNaN(_vm.goods.process_charge)
                                ? _vm.lvPrice.taxPrice
                                : _vm.lvPrice.taxPrice +
                                    _vm.goods.process_charge
                            )
                        ),
                      ])
                    : _c("p", [_vm._v("¥" + _vm._s(_vm.goods.retail_price))]),
                ]),
                !!_vm.goods.weight && _vm.goods.weight > 0
                  ? _c("div", { staticClass: "box1" }, [
                      _c("span", [_vm._v("单位重量")]),
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.goods.weight + _vm.goods.units) +
                            "/" +
                            _vm._s(_vm.goods.unit)
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "box1" }, [
                  _c("span", [_vm._v("起订量")]),
                  _c("p", [
                    _vm._v(_vm._s(_vm.goods.minimum_purchase + _vm.goods.unit)),
                  ]),
                ]),
                _c("div", { staticClass: "box1" }, [
                  _c("span", [_vm._v("材质")]),
                  _c("p", [_vm._v(_vm._s(_vm.goods.material))]),
                ]),
                _c("div", { staticClass: "box1" }, [
                  _c("span", [_vm._v("地区")]),
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.goods.province) + " " + _vm._s(_vm.goods.city)
                    ),
                  ]),
                ]),
                _vm.tag.length > 0
                  ? _vm._l(_vm.tag, function (items, index) {
                      return _c("Tag", {
                        key: items.id,
                        attrs: { ID: index, data: items },
                        on: { getData: _vm.getData },
                      })
                    })
                  : _vm._e(),
                _c("div", { staticClass: "box1" }, [
                  _c("span", [_vm._v("数量")]),
                  _c(
                    "div",
                    { staticClass: "num-box" },
                    [
                      _c("el-input-number", {
                        attrs: {
                          size: "small",
                          min: _vm.goods.minimum_purchase,
                          precision: 0,
                        },
                        model: {
                          value: _vm.cart.number,
                          callback: function ($$v) {
                            _vm.$set(_vm.cart, "number", $$v)
                          },
                          expression: "cart.number",
                        },
                      }),
                    ],
                    1
                  ),
                  this.tagNumber > 0
                    ? _c("div", [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#0a0c0b",
                              "padding-right": "10px",
                            },
                          },
                          [_vm._v("现有")]
                        ),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#0a0c0b",
                              "padding-left": "10px",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.goodNum == undefined
                                  ? _vm.goods.inventory
                                  : _vm.goodNum
                              )
                            ),
                          ]
                        ),
                      ])
                    : _c("div", [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#0a0c0b",
                              "padding-right": "10px",
                            },
                          },
                          [_vm._v("现有")]
                        ),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#0a0c0b",
                              "padding-left": "10px",
                            },
                          },
                          [_vm._v(_vm._s(_vm.goods.inventory))]
                        ),
                      ]),
                ]),
                _c(
                  "div",
                  { staticStyle: { display: "flex", "flex-direction": "row" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "addCart",
                        style: _vm.isExtis
                          ? ""
                          : "background: #6d737b;cursor:not-allowed;",
                        on: { click: _vm.add },
                      },
                      [_vm._v("加入采购单")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "addCart",
                        style: _vm.isExtis
                          ? ""
                          : "background: #6d737b;cursor:not-allowed;",
                        on: { click: _vm.buyGoods },
                      },
                      [_vm._v("立即购买")]
                    ),
                  ]
                ),
              ],
              2
            ),
          ]),
          _c("div", { staticClass: "introduce" }, [
            _c("h3", { staticStyle: { "font-size": "14px" } }, [
              _vm._v("产品介绍"),
            ]),
            _c("div", { staticClass: "introduce-info" }, [
              _c("p", [_vm._v("商品名称：" + _vm._s(_vm.goods.name))]),
              _c("p", [_vm._v("商品单位：" + _vm._s(_vm.goods.unit))]),
              _c("p", [_vm._v("材质：" + _vm._s(_vm.goods.material))]),
              _vm.goods.weight != undefined
                ? _c("p", [
                    _vm._v(
                      "重量：" + _vm._s(_vm.goods.weight + _vm.goods.units)
                    ),
                  ])
                : _vm._e(),
              _c("p", [
                _vm._v(
                  "地区：" +
                    _vm._s(_vm.goods.province) +
                    " " +
                    _vm._s(_vm.goods.city)
                ),
              ]),
              _c("p", [_vm._v("仓库：" + _vm._s(_vm.goods.entrepot))]),
              _c("p", [_vm._v("钢厂：" + _vm._s(_vm.goods.manufacturer))]),
              _vm.goods.process_charge != undefined
                ? _c("p", [
                    _vm._v(
                      "加工费：￥" +
                        _vm._s(_vm.goods.process_charge) +
                        "（已包含在商品价格中）"
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm.attribute.length !== 0
            ? _c("div", { staticClass: "introduce" }, [
                _c("h3", { staticStyle: { "font-size": "14px" } }, [
                  _vm._v("产品参数"),
                ]),
                _c(
                  "div",
                  { staticClass: "introduce-info" },
                  _vm._l(_vm.attribute, function (item) {
                    return _c("p", { key: item.id }, [
                      _vm._v(
                        _vm._s(item.attribute) + "：" + _vm._s(item.value)
                      ),
                    ])
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "details" }, [
            _c("h3", { staticStyle: { "font-size": "14px" } }, [
              _vm._v("产品详情"),
            ]),
            _c("div", { staticClass: "details-info" }, [
              _c("div", { domProps: { innerHTML: _vm._s(_vm.goods.detail) } }),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "sidebar" }, [
          _c("div", { staticClass: "supplier" }, [
            _c("h3", { staticStyle: { "font-size": "14px" } }, [
              _vm._v("企业信息"),
            ]),
            _c(
              "div",
              { staticClass: "supplier-info" },
              [
                _c("p", [
                  _vm._v(
                    "公司名称：" +
                      _vm._s(
                        _vm._f("hideTextInfo")(
                          _vm.clientInfo.clientName,
                          3,
                          4
                        ) || "--"
                      )
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "公司地址：" +
                      _vm._s(
                        _vm._f("hideTextInfo")(_vm.clientInfo.offAddr, 3, 4) ||
                          "--"
                      )
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "企业认证：" +
                      _vm._s(
                        _vm.clientInfo.apprvStatus == "P" ? "已认证" : "未认证"
                      )
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "工作日营业时间：" +
                      _vm._s(_vm.brandInfo.startTime) +
                      " 至 " +
                      _vm._s(_vm.brandInfo.endTime)
                  ),
                ]),
                _vm.brandInfo.noWorkStartTime == _vm.brandInfo.noWorkEndTime
                  ? _c("p", [_vm._v("非工作日营业时间：不营业")])
                  : _c("p", [
                      _vm._v(
                        "非工作日营业时间：" +
                          _vm._s(_vm.brandInfo.noWorkStartTime) +
                          " 至 " +
                          _vm._s(_vm.brandInfo.noWorkEndTime)
                      ),
                    ]),
                _c(
                  "el-button",
                  {
                    staticClass: "btn",
                    attrs: { plain: "" },
                    on: { click: _vm.toStoreDetail },
                  },
                  [_vm._v("进入供应商店铺")]
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "supplier", staticStyle: { "margin-top": "20px" } },
            [
              _c("h3", { staticStyle: { "font-size": "14px" } }, [
                _vm._v("最新铝锭价"),
              ]),
              _c("div", { staticClass: "supplier-info" }, [
                _c("p", [
                  _vm._v("南海灵通铝锭价(含票)："),
                  _c("span", { staticStyle: { color: "red" } }, [
                    _vm._v("￥" + _vm._s(_vm.lvPrice.taxPrice || 0)),
                  ]),
                ]),
                _c("p", [
                  _vm._v(
                    "铝锭价公布日期：" + _vm._s(_vm.lvPrice.publishDay || "--")
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }