var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "box" }, [
    _c("div", { staticClass: "box-title" }, [_vm._v(_vm._s(_vm.data.name))]),
    _c(
      "div",
      { staticClass: "box-buttons" },
      _vm._l(_vm.data.valueList, function (item) {
        return _c(
          "div",
          {
            key: item.id,
            staticClass: "botton",
            class: [item.id == _vm.index ? "Selected" : ""],
            on: {
              click: function ($event) {
                return _vm.setData(item)
              },
            },
          },
          [_vm._v(" " + _vm._s(item.value))]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }