<template>
  <div class="wrap">
    <div class="container">
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/home/HomePage' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>产品详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div class="content">
        <div class="main">
          <div class="info">
            <div class="info-left">
              <div class="image-box">
                <el-image :src="goods.pic_url" fit="fill">
                  <div slot="placeholder" class="image-slot">
                    加载中<span class="dot">...</span>
                  </div>
                </el-image>
              </div>
              <div class="image-list">
                <div class="image-item" v-for="item in goods.gallery" :key="item.id" @click="selectedPreview(item)">
                  <el-image :src="item" fit="cover">
                    <div slot="placeholder" class="image-slot">
                      加载中<span class="dot">...</span>
                    </div>
                  </el-image>
                </div>
              </div>
            </div>

            <div class="info-right">
              <div class="name">
                <span>{{goods.existing?'期货':'现货'}}</span>
                <p>{{goods.name}}</p>
              </div>
              <div class="box1 price">
                <span>商品价格</span>
                <!--{{goods.is_lvding_price?'南海灵通铝价':'单价'}}-->
                <!--<p v-if="goods.retail_price=='面议'">{{goods.money}}</p>-->
                <p v-if="isExtis">¥{{goods.retail_price}}</p>
                <p v-else-if="goods.is_lvding_price">¥{{isNaN(goods.process_charge)?lvPrice.taxPrice:lvPrice.taxPrice+goods.process_charge }}</p>
                <p v-else>¥{{goods.retail_price}}</p>
              </div>
              <div class="box1" v-if="!!goods.weight && goods.weight > 0">
                <span>单位重量</span>
                <p>{{goods.weight+goods.units}}/{{goods.unit}}</p>
              </div>
              <div class="box1">
                <span>起订量</span>
                <p>{{goods.minimum_purchase+goods.unit}}</p>
              </div>
              <div class="box1">
                <span>材质</span>
                <p>{{goods.material}}</p>
              </div>
              <div class="box1">
                <span>地区</span>
                <p>{{goods.province}} {{goods.city}}</p>
              </div>
              <!--<div class="box1">-->
              <!--  <span>支付方式</span>-->
              <!--  <div class="pay-method">-->
              <!--    <div class="pay-method-item">支付宝</div>-->
              <!--    <div class="pay-method-item">微信支付</div>-->
              <!--    <div class="pay-method-item">中国银联</div>-->
              <!--  </div>-->
              <!--</div>-->
              <!-- <div class="box" v-for="items in tag" :key="items.id">
                <div class="box-title">{{items.title}}</div>
                <div class="box-buttons">
                  <div class="botton" v-for="item in items.value" :key="item.id" @click="change(items.id,item)"
                    :class="[]">{{item.name}}</div>
                </div>
              </div> -->
              <template v-if="tag.length>0">
                <Tag  v-for="(items,index) in tag" :key="items.id" :ID='index' :data='items' @getData="getData"></Tag>
              </template>

              <div class="box1">
                <span>数量</span>
                <div class="num-box">
                  <el-input-number size="small" v-model="cart.number" :min="goods.minimum_purchase" :precision="0"></el-input-number>
                </div>
                <div v-if="this.tagNumber>0">
                  <span style="color: #0a0c0b;padding-right: 10px;">现有</span><span style="color: #0a0c0b;padding-left: 10px;">{{goodNum==undefined?goods.inventory:goodNum}}</span>
                </div>
                <div v-else>
                  <span style="color: #0a0c0b;padding-right: 10px;">现有</span><span style="color: #0a0c0b;padding-left: 10px;">{{goods.inventory}}</span>
                </div>
              </div>
              <!--<div class="addCart" v-if="goods.money=='面议'">询价</div>-->
              <div style="display: flex; flex-direction: row">
                <div class="addCart" :style="isExtis?'':'background: #6d737b;cursor:not-allowed;'" @click="add">加入采购单</div>
                <div class="addCart" :style="isExtis?'':'background: #6d737b;cursor:not-allowed;'" @click="buyGoods">立即购买</div>
              </div>
            </div>
          </div>
          <!-- 产品介绍 -->
          <div class="introduce">
            <h3 style="font-size:14px">产品介绍</h3>
            <div class="introduce-info">
              <p>商品名称：{{goods.name}}</p>
              <!--<p>规格：{{goods.size}}</p>-->
              <p>商品单位：{{goods.unit}}</p>
              <p>材质：{{goods.material}}</p>
              <p v-if="goods.weight!=undefined">重量：{{goods.weight+goods.units}}</p>
              <p>地区：{{goods.province}} {{goods.city}}</p>
              <p>仓库：{{goods.entrepot}}</p>
              <p>钢厂：{{goods.manufacturer}}</p>
              <p v-if="goods.process_charge!=undefined">加工费：￥{{goods.process_charge}}（已包含在商品价格中）</p>
            </div>
          </div>
          <div class="introduce" v-if="attribute.length!==0">
            <h3 style="font-size:14px">产品参数</h3>
            <div class="introduce-info">
              <p v-for="item in attribute" :key="item.id">{{item.attribute}}：{{item.value}}</p>
            </div>
          </div>
          <!-- 产品详情 -->
          <div class="details">
            <h3 style="font-size:14px">产品详情</h3>
            <div class="details-info">
              <!--<el-image v-for="url in imageList" :key="url.id" :src="url.imgUrl" lazy></el-image>-->
                <div v-html="goods.detail"></div>
            </div>
          </div>
        </div>

        <div class="sidebar">
          <div class="supplier">
            <h3 style="font-size:14px">企业信息</h3>
            <div class="supplier-info">
              <p>公司名称：{{clientInfo.clientName | hideTextInfo(3, 4) || '--'}}</p>
              <p>公司地址：{{clientInfo.offAddr | hideTextInfo(3, 4) || '--'}}</p>
              <p>企业认证：{{clientInfo.apprvStatus=='P' ? '已认证' : '未认证'}}</p>
              <p>工作日营业时间：{{brandInfo.startTime}} 至 {{brandInfo.endTime}}</p>
              <p v-if="brandInfo.noWorkStartTime==brandInfo.noWorkEndTime">非工作日营业时间：不营业</p>
              <p v-else>非工作日营业时间：{{brandInfo.noWorkStartTime}} 至 {{brandInfo.noWorkEndTime}}</p>
              <!-- <p>联系电话：{{clientInfo.phone | hideTextInfo(3, 4) || '--'}}</p> -->
              <el-button plain class="btn" @click="toStoreDetail">进入供应商店铺</el-button>
            </div>
          </div>
          <div class="supplier"
            style="margin-top:20px">
            <h3 style="font-size:14px">最新铝锭价</h3>
            <div class="supplier-info">
              <p>南海灵通铝锭价(含票)：<span style="color: red;">￥{{lvPrice.taxPrice || 0}}</span></p>
              <p>铝锭价公布日期：{{lvPrice.publishDay || '--'}}</p>
            </div>
          </div>
          <!-- 猜你喜欢 -->
          <!-- <div class="guess-you-like">
            <h3>猜你喜欢</h3>
            <div class="like-list">
              <div class="like-item" v-for="item in guessYouLikeList" :key="item.id">
                <div class="like-image">
                  <el-image :src="item.imgUrl" fit="cover">
                    <div slot="placeholder" class="image-slot">
                      加载中<span class="dot">...</span>
                    </div>
                  </el-image>
                </div>
                <div class="like-info">
                  <p>{{item.name}}</p>
                  <p>材质：{{item.material}}</p>
                  <p>{{item.address}}</p>
                  <p class="price">￥{{item.price}}</p>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {goodsDetailAndClient, addCart, isMyGoods,newestLvdingPrice} from "../api";
import { hideTextInfo } from '@/utils/util';
import Tag from '../component/Tag.vue'
export default {
  data() {
    return {
      isLogin: Boolean(localStorage.getItem('loginName')),
      goods:{
        city: "",
        weight: 0,
        specification: "",
        units: "",
        retail_price: 0,
        client_id: 0,
        manufacturer: "",
        unit: "",
        minimum_purchase: 1,
        material: "",
        size: "",
        province: "",
        name: "",
        id: 0,
        entrepot: "",
        detail: "",
        existing:'',
        gallery: [],
        pic_url:''
      },
      guessYouLikeList: [
        {
          id: 1,
          name: '热镀锌彩涂',
          imgUrl: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
          material: 'DX51D',
          address: '广东佛山',
          price: '4700'
        },{
          id: 2,
          name: '热镀锌彩涂',
          imgUrl: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
          material: 'DX51D',
          address: '广东佛山',
          price: '4700'
        },{
          id: 3,
          name: '热镀锌彩涂',
          imgUrl: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
          material: 'DX51D',
          address: '广东佛山',
          price: '4700'
        }
      ],
      clientInfo:{
      },
      cart:{
        goodsId:'',
        goodsName:'',
        price:'',
        number:1,
        specifications:[],
        picUrl:'',
      },
      productList:{},
      tag: [{}],
      //是否存在该商品
      isExtis:false,
      //商品现存量
      goodNum:undefined,
      //商品选择的规格
      checkSpec:[],
      //商品货物id
      productId:null,
      attribute:[],
      //南海灵通铝价
      lvPrice:{},
      //初始图片集合，没有选中的规格时显示
      initGallery:{},
      tagNumber:0,
      //店铺对象
      brandInfo:{},
    }
  },
  filters: {
    hideTextInfo
  },
  methods: {
    // 选择预览图
    selectedPreview(data) {
      this.goods.pic_url = data;
    },
    receive(id){
      goodsDetailAndClient({id:id}).then(
        res => {
          this.brandInfo=res.data.brand;
          console.log(this.brandInfo)
          this.goods=res.data.goods;
          this.goods.gallery.unshift(this.goods.pic_url)
          //初始图片集合
          Object.assign(this.initGallery,this.goods.gallery)

          this.clientInfo=res.data.clientInfo;
          this.tag=res.data.specificationList;
          this.productList=res.data.productList;
          this.attribute=res.data.attribute;
          this.cart.number=res.data.goods.minimum_purchase;
          //没有填写规格时
          if(this.tag.length<=0){
            this.isExtis=true
          }
        }
      )
    },
    add(){
      var _this = this;
      if(!this.verify()) return
      this.cart.productId=this.productId
      this.cart.goodsId=this.goods.id
      this.cart.goodsName=this.goods.name
      this.cart.price=this.goods.retail_price
      this.cart.picUrl=this.goods.pic_url
      addCart(this.cart).then(res => {
          this.cart.specifications=[]
          if(res.errno===0){
            _this.$store.commit('setCarCount', res.data);
            this.$notify.info({
              title: '提示',
              message: '添加成功',
              duration: 1000
            });
          }else{
            // this.$message.error(res.errmsg);
            // this.$router.push('/home/Login')
          }
        }
      )
    },
    buyGoods(){
      if(!this.verify()) return

      isMyGoods({goodId:this.goods.id,productId:this.productId,number:this.cart.number}).then(res=>{
        if(res.errno===0&&res.data===true){
          this.$router.push({path:'/home/Mall/ShoppingList/ConfirmOrder',query:{goodId:this.goods.id,productId:this.productId,number:this.cart.number}})
        }
      })
      // this.$router.push({path:'/home/Mall/ShoppingList/ConfirmOrder',query:{goodId:this.goods.id,productId:this.productId,number:this.cart.number}})
    },
    toStoreDetail() {
      //进入供应商店铺
      this.$router.push({
        path: '/home/Mall/StoreDetail?clientId='+this.clientInfo.clientInfoId
      });
    },

    getData(e,i){
      if(e!==null){
        if(this.checkSpec[i]==null){
          this.tagNumber+=1
        }
        this.checkSpec[i]=e.value
      }else{
        delete this.checkSpec[i]
        this.tagNumber-=1
      }
      this.isExtis=false;
      Object.assign(this.goods.inventory,this.goodNum)
      this.productId=null;

      // this.goods.retail_price=0
      let result=true;
      //得到相同的规格货物信息
      this.productList.forEach((item) => {
        if(this.arrEuqalse(this.checkSpec,item.specifications)){
          this.isExtis=true;
          this.goodNum=item.number;
          this.goods.retail_price=item.price;
          this.productId=item.id
          if(item.url!==""){
            //清空之前图片，重新绑定
            this.goods.gallery[0]=item.url
            // this.goods.gallery.push(item.url)
            this.goods.pic_url=item.url
          }else{
            this.goods.gallery=this.initGallery
            this.goods.pic_url=this.initGallery[0];
          }
          result=false;
          return;
        }
      })
      if(this.tagNumber>0 && !this.isExtis){
        this.goodNum=0
      }
      if(result){
        Object.assign(this.goods.gallery,this.initGallery)
        this.goods.pic_url=this.initGallery[0];
      }

    },
    verify(){
      var _this = this;
      if (!_this.isLogin) {
        this.$confirm('当前未登录, 是否登录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _this.$router.push({
            path: '/home/Login'
          });
        }).catch(() => {

        });
        return false
      }
      if(!this.isExtis){
        // this.$notify.info({
        //   title:"提示",
        //   message:"没有该规格的商品",
        //   duration:1000
        // })
        return false
      }
      if(this.cart.number<this.goods.minimum_purchase){
        this.$notify.info({
          title:"提示",
          message:"购买数量小于起订量",
          duration:1000
        })
        return false
      }
      //前端判断商品数量
      if(this.tag.length>0){
        if(this.goodNum<this.cart.number){
          this.$notify.info({
            title:"提示",
            message:"该规格库存不足",
            duration:1000
          })
          return false
        }
      }else{
        if(this.goods.inventory<this.cart.number){
          this.$notify.info({
            title:"提示",
            message:"库存不足",
            duration:1000
          })
          return false
        }
      }
      return true
    },
    arrEuqalse(arr1, arr2) {
      if (!arr1 || !arr2) {
        return false;
      }
      //清除空白元素
      // arr1=;
      if (arr1.filter(v=> v.trim||v).length != arr2.length) {
        return false;
      }

      for (var i = 0; i < arr2.length; i++) {
        if (arr1[i] != arr2[i]) {
          return false;
        }
      }

      for (var i = 0; i < arr1.length; i++) {
        if (arr1[i] != arr2[i]) {
          return false;
        }
      }
      return true;
    },
    newestLvdingPrice() {
      newestLvdingPrice().then(response=>{
        this.lvPrice=response.data
      })
    }
  },
  watch: {

  },
  mounted() {
    const id=this.$route.query.id;
    this.receive(id);
    this.newestLvdingPrice();
  },
  components:{
    Tag
  }
}
</script>

<style lang="less" scoped>
  @deep: ~">>>";
  .head_h3 {
    position: relative;
    padding: 16px 0 16px 20px;
    border-bottom: 1px solid #E8E8E8;
    font-size: 18px;
    color: #333333;
    margin: 0;

    &::after {
      position: absolute;
      left: 0;
      top: 50%;
      content: '';
      width: 4px;
      height: 20px;
      // background-image: linear-gradient(to bottom, #ACB2CA, #33A2C5);
      background: #409eff;
      margin-top: -10px;
    }
  }
  ul li {
    list-style: none;
  }
  h1,h3 {
    margin: 0;
    padding: 0;
  }
  .el-image {
    width: 100%;
    height: 100%;
  }
  .wrap {
    width: 100%;
    background: #F6F6F6;
    overflow: hidden;
  }
  .container {
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;

    .breadcrumb {
      margin: 18px 0;
    }
    .content {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      overflow: hidden;

      .main {
        flex: 1;

        .info {
          background: #fff;
          padding: 20px;
          display: flex;
          justify-content: space-between;

          .info-left {
            width: 340px;

            .image-box {
              width: 100%;
              height: 340px;
            }

            .image-list {
              display: flex;
              margin-top: 12px;

              .image-item {
                width: 60px;
                height: 60px;
                margin-right: 12px;
                cursor: pointer;

                &:last-child {
                  margin-right: 0;
                }
              }
            }
          }
          .info-right {
            flex: 1;
            margin-left: 26px;
            padding-top: 8px;

            .name {
              display: flex;
              align-items: center;

              span {
                font-size: 11px;
                background: #FF4400;
                color: #fff;
                padding: 1px 4px;
                border-radius: 2px;
              }
              p {
                font-size: 18px;
                font-weight: 600;
                margin-left: 8px;
              }
            }
            .box1 {
              display: flex;
              align-items: center;
              margin-top: 18px;

              span {
                width: 100px;
                text-align: justify;
                text-align-last: justify;
                color: #A9A9A9;
                padding:  0 24px;
                box-sizing: border-box;
              }
              p {
                // font-weight: bold;
                // font-size: 14px;
              }
              &.price {
                background: #EFFAFF;
                padding: 16px 0;
                margin-top: 30px;

                p {
                  color: #FE4301;
                  font-weight: bold;
                  font-size: 20px;
                }
              }
              .pay-method {
                display: flex;

                .pay-method-item {
                  border: 1px solid #E4E4E4;
                  width: 90px;
                  height: 32px;
                  margin-right: 8px;
                  text-align: center;
                  line-height: 32px;
                }
              }
              .num-box {
                display: flex;
              }
            }
            .addCart {
              width: 144px;
              height: 40px;
              color: #fff;
              text-align: center;
              line-height: 40px;
              // font-weight: 600;
              border-radius: 3px;
              margin: 24px 0 0 24px;
              // background-image: linear-gradient(to right, #F67637, #FF571A);
              background: #F40;
              cursor: pointer;
              font-size: 16px;
              &:hover {
                // background-image: linear-gradient(to right, #FF571A, #F67637);
                background: #F22D00;
              }
            }
          }
        }
        .introduce {
          background: #fff;
          margin-top: 16px;

          h3 {
            position: relative;
            padding: 14px 0 14px 16px;
            border-bottom: 1px solid #E4E4E4;
            font-size: 17px;

            &::after {
              position: absolute;
              left: 0;
              top: 50%;
              content: '';
              width: 4px;
              height: 18px;
              // background-image: linear-gradient(to bottom, #ACB2CA, #33A2C5);
              background: #409eff;
              margin-top: -7px;
            }
          }
          .introduce-info {
            padding: 20px;
            display: flex;
            flex-wrap: wrap;

            p {
              width: 25%;
              margin-bottom: 10px;
              color: #A0A0A0;
              font-size: 12px;
            }
          }
        }
        .details {
          background: #fff;
          margin-top: 16px;
          margin-bottom: 50px;

          h3 {
            position: relative;
            padding: 14px 0 14px 16px;
            border-bottom: 1px solid #E4E4E4;
            font-size: 17px;

            &::after {
              position: absolute;
              left: 0;
              top: 50%;
              content: '';
              width: 4px;
              height: 18px;
              // background-image: linear-gradient(to bottom, #ACB2CA, #33A2C5);
              background: #409eff;
              margin-top: -7px;
            }
          }
          .details-info {
            padding: 20px;

            & @{deep} img {
              width: 100%;
            }
          }
        }
      }

      .sidebar {
        width: 290px;
        margin-left: 16px;

        .supplier {
          background: #fff;

          h3 {
            .head_h3();
          }
          .supplier-info {
            padding: 24px;
            font-size: 12px;
            // font-weight: bold;
            color: #333333;

            p {
              margin-bottom: 14px;

              .see {
                color: #2ca2c6;
                cursor: pointer;
                margin-left: 16px;
              }
            }
            .btn {
              display: block;
              margin: 30px auto 0 auto;
            }
          }
        }
        .guess-you-like {
          background: #fff;
          margin-top: 20px;

          h3 {
            position: relative;
            padding: 14px 0 14px 16px;
            border-bottom: 1px solid #E4E4E4;
            font-size: 17px;

            &::after {
              position: absolute;
              left: 0;
              top: 50%;
              content: '';
              width: 4px;
              height: 18px;
              // background-image: linear-gradient(to bottom, #ACB2CA, #33A2C5);
              background: #409eff;
              margin-top: -7px;
            }
          }
          .like-list {
            padding: 20px;

            .like-item {
              display: flex;
              margin-bottom: 20px;

              .like-image {
                width: 100px;
                height: 100px;
              }
              .like-info {
                flex: 1;
                margin-left: 16px;

                p {
                  font-weight: bold;
                  font-size: 15px;
                  color: #7F7F7F;
                  margin-bottom: 4px;

                  &.price {
                    color: #FE4301;
                    font-size: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
